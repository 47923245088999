import React, { useState, useEffect } from 'react';

import OportunidadeReadOnly from "./Form/ReadOnly";
import { createColumns } from './Columns'
import Table from '../../../components/Table'
import Comentarios from './Comentarios';
import {
    Spinner,
    Modal,
    Row,
    Col,
} from "reactstrap";

// export function goToPrint(id) {
//     props.history.push(`/admin/oportunidades/${new Number(id)}/print`)
// }

export default ({
    oportunidades,
    pageProperties,
    notify,
    loading,
    readOnly,
    isConversao,
    ...props }) => {




    const [oportunidadeId, setOportunidadeId] = useState(undefined)

    const [comentariosOportunidade, setComentariosOportunidade] = useState(null)
    const [showModalComentarios, setShowModalComentarios] = useState(false)

    function goToEdit(id) {
        props.history.push(`/admin/oportunidades/${new Number(id)}/edit`)
    }
//TODO: Copy this function to the new page. 
    function goToPrint(id) {
        props.history.push(`/admin/oportunidades/${new Number(id)}/print`)
    }

    function handleShowOportunidade(id) {
        setOportunidadeId(id)
    }

    useEffect(() => {
        if (comentariosOportunidade && comentariosOportunidade.id && comentariosOportunidade.id > 0) {
            setShowModalComentarios(true)
        }
    }, [comentariosOportunidade])


    function handleCloseModalComentarios() {
        setShowModalComentarios(false)
        setComentariosOportunidade(null)
        props.reloadOportunidades();

    }

    return (
        <>
            {/*Modal dos Comentarios*/}
            <Modal
                size="lg"
                className="modal-dialog-centered"
                isOpen={showModalComentarios}
                toggle={() => handleCloseModalComentarios()}
            >
                <div className="modal-header" style={{ paddingBottom: 0 }}>
                    <h5 className="modal-title" id="exampleModalLabel">
                        Contactações
                        <br />
                        <small className="text-muted"
                            title={`Código Oportunidade`}>
                            {comentariosOportunidade ? "#" + comentariosOportunidade.id : ""}
                        </small>
                        <br />
                        <small className="text-muted"
                            title={`Nome do Cliente`}>
                            {comentariosOportunidade ? comentariosOportunidade.cliente.nome : ""}
                        </small>
                    </h5>

                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={handleCloseModalComentarios}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body" style={{ paddingTop: 5 }}>
                    <Row>
                        <Col lg='12'>
                            {/* <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                            >
                                <h3>Comentários</h3>
                            </div> */}
                            <Comentarios
                                readOnly={readOnly}
                                notify={notify}
                                oportunidadeId={comentariosOportunidade ? comentariosOportunidade.id : 0}
                                oportunidadeIdToCrossSelling={null}
                                tipoCrossSelling={null}
                                onComentariosChanged={null}
                            />
                        </Col>
                    </Row>
                </div>
            </Modal>

            <OportunidadeReadOnly
                show={oportunidadeId}
                oportunidadeId={oportunidadeId}
                onClose={() => setOportunidadeId(undefined)}
            />
            {
                loading && (!oportunidades || oportunidades.length === 0) ?
                    <div style={{
                        display: 'flex',
                        flex: 1,
                        justifyContent: 'center'
                    }}>
                        <Spinner
                            color='primary'
                        />
                    </div>
                    :
                    <Table
                        columns={createColumns({
                            edit: readOnly ? handleShowOportunidade : goToEdit,
                            // printThis: readOnly ? handleShowOportunidade : goToPrint
                            printThis: goToPrint,
                            isConversao,
                            setComentariosOportunidade: setComentariosOportunidade
                        })}
                        data={oportunidades}
                        pageProperties={pageProperties}
                        onTableChange={props.onTableChange}
                        paginationLengths={[10,25,50,100, 500]}

                    />
            }
        </>
    );
}
